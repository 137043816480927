import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Stack, Typography, MenuItem, Alert } from '@mui/material';
import { BasicTextField } from '../components/BasicTextField';
import { SelectField } from '../components/SelectField';

export const SoftwareInformation = () => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1" gutterBottom>
        Software Information (Required immediately)
      </Typography>
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        alignItems="flex-end"
      >
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Software Name
          </Typography>
          <BasicTextField
            name="nameOfTechnology"
            label="Software Name"
            error={errors.nameOfTechnology !== undefined}
            helperText={errors.nameOfTechnology?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Software Url
          </Typography>
          <BasicTextField
            name="urlOfTheProduct"
            label="Software Url"
            error={errors.urlOfTheProduct !== undefined}
            helperText={errors.urlOfTheProduct?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Who will use this technology?
          </Typography>
          <SelectField
            name="whoUses"
            label="Tech Users"
            error={errors.whoUses !== undefined}
            helperText={errors.whoUses?.message}
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="team">Team</MenuItem>
            <MenuItem value="enterprize">Enterprise</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }} justifyContent="space-between">
          <Typography variant="body1" paragraph gutterBottom>
            Tech Category: Primary purpose of the technology being proposed. In cases where it fits
            in multiple choose the majority use case.
          </Typography>
          <SelectField name="techCategory" label="Tech Category">
            <MenuItem value="cloud-infrastructure">Cloud infrastructure</MenuItem>
            <MenuItem value="comm-as-service">Communication as a Service</MenuItem>
            <MenuItem value="data-management">Data management & development </MenuItem>
            <MenuItem value="design-ux">Design and User Experience</MenuItem>
            <MenuItem value="hr-tech">HR technology</MenuItem>
            <MenuItem value="mkrt-tech">Marketing technology</MenuItem>
          </SelectField>
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }} justifyContent="space-between">
          <Typography variant="body1" paragraph gutterBottom>
            ( If applicable) Sub Category: Please select the sub-category for the software you are
            requesting
          </Typography>
          <SelectField name="subCategory" label="Sub Category">
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="chat">Chat</MenuItem>
            <MenuItem value="video-conferencing">Video Conferencing</MenuItem>
            <MenuItem value="telephone">Telephone</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}>
        <Typography variant="body1" paragraph gutterBottom>
          Describe what this technology does
        </Typography>
        <BasicTextField
          name="description"
          label="Software Description"
          multiline
          rows={2}
          error={errors.description !== undefined}
          helperText={errors.description?.message}
        />
      </Stack>
      <Stack sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}>
        <Typography variant="body1" paragraph gutterBottom>
          What is the business use case or business value this tool provides RV? (Provide
          description of why you are proposing this change in technology)
        </Typography>
        <BasicTextField
          name="feedback_business_justification"
          label="Business Use Case"
          multiline
          rows={4}
          error={errors.feedback_business_justification !== undefined}
          helperText={errors.feedback_business_justification?.message}
        />
      </Stack>
      <Stack direction="row" spacing={4} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Does a proof of concept exist for this software?
          </Typography>
          <SelectField
            name="proofOfConcept"
            label="Proof of Concept"
            error={errors.proofOfConcept !== undefined}
            helperText={errors.proofOfConcept?.message}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </SelectField>
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Will Single Sign-on (SSO) be used for this technology?
          </Typography>
          <SelectField
            name="singleSignOn"
            label="Single Sign-on"
            error={errors.singleSignOn !== undefined}
            helperText={errors.singleSignOn?.message}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
            <MenuItem value="unsure">Not Applicable</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Who will be responsible for administering the project? If you are unsure, please put
            N/A.
          </Typography>
          <BasicTextField
            name="responsibleName"
            label="Project Admin"
            error={errors.responsibleName !== undefined}
            helperText={errors.responsibleName?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Will software need to be installed on end user desktops (Mac/PC) in order to use this
            technology?
          </Typography>
          <SelectField
            name="desktopInstalled"
            label="Software Installation"
            error={errors.desktopInstalled !== undefined}
            helperText={errors.desktopInstalled?.message}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack spacing={2} sx={{ width: '50%' }}>
        <Typography variant="body1" paragraph gutterBottom>
          Does another team need to be involved in order to use this technology?
        </Typography>
        <Alert severity="info">
          Examples of needing outside team/resource help would include integrating with existing
          tools, enabling plugins with existing tools. specific knowledge not available on the team,
          or just extra resource help.
        </Alert>
        <SelectField name="anotherTeamInvolved" label="Team Involvement">
          <MenuItem value="yes">Yes</MenuItem>
          <MenuItem value="no">No</MenuItem>
        </SelectField>
      </Stack>
    </Box>
  );
};
