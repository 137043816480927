import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Stack, Typography, MenuItem, Alert } from '@mui/material';
import { EmployeeAutocomplete } from '../components/EmployeeAutocomplete';
import { CompletionDate } from '../components/CompletionDate';
import { SelectField } from '../components/SelectField';
import { SwitchInput } from '../components/SwitchInput';
import { RadioButtonGroup } from '../components/RadioButtonGroup';
import { useAPI } from '../Providers/APIProvider';

export const RequestInformation = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const { getEmployees } = useAPI();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const employees = await getEmployees();
      if (!employees) {
        return [];
      }
      setOptions(employees);
      return employees;
    };
    getOptions();
  }, []);

  const industry = [
    {
      industryGroup: 'Education',
      industryLead: 'Colin Mutter',
    },
    {
      industryGroup: 'Financial Services',
      industryLead: 'Kyle Getson',
    },
    {
      industryGroup: 'Corporate',
      industryLead: 'Jonathan Desrochers',
    },
    {
      industryGroup: 'Energy',
      industryLead: 'April Gibson-Fulton',
    },
    {
      industryGroup: 'Media & Commerce',
      industryLead: 'Maulan Byron',
    },
    {
      industryGroup: 'Puerto Rico',
      industryLead: 'Jonathan Desrochers',
    },
    {
      industryGroup: 'Red Digital',
      industryLead: 'Chris Bestall',
    },
    {
      industryGroup: 'Travel',
      industryLead: 'Chris Whyde',
    },
  ];

  const industryGroup = watch('industry_group');
  const escalateRequest = watch('escalateRequest');
  const itVerticalLeadConversation = watch('itVerticalLeadConversation');

  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1" gutterBottom>
        Request Information (Required immediately)
      </Typography>
      <Stack
        sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}
        spacing={2}
        justifyContent="space-between"
      >
        <Typography variant="body1" paragraph gutterBottom>
          Please provide a a champion for this request. A champion is an individual or list of
          individuals responsible for the request and evaluation of software.
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <EmployeeAutocomplete
          name="champions"
          label="Champions"
          options={options}
          getOptionLabel={(option) => option.name}
          error={errors.champions !== undefined}
          helperText={errors.champions?.message}
        />
      </Stack>
      <Stack direction="row" spacing={4} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          <Typography variant="body1" paragraph gutterBottom>
            Senior Director or VP for request approval<span style={{ color: 'red' }}>*</span>
          </Typography>
          <EmployeeAutocomplete
            name="vpTechCabLeaders"
            label="Senior Approval"
            options={options}
            getOptionLabel={(option) => option.name}
            error={errors.vpTechCabLeaders !== undefined}
            helperText={errors.vpTechCabLeaders?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          <Typography variant="body1" paragraph gutterBottom>
            What type of request is this for RV technology?<span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="typeOfRequest"
            label="Request Type"
            error={errors.typeOfRequest !== undefined}
            helperText={errors.typeOfRequest?.message}
          >
            <MenuItem value="new">New - Onboard new technology</MenuItem>
            <MenuItem value="modify">Modify - Update existing technology</MenuItem>
            <MenuItem value="remove">Remove - Change status of tech to depreciate</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}
      >
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          <Typography variant="body1" paragraph gutterBottom>
            To properly allocate the cost of this technology, please select an industry group.
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="industry_group"
            label="Industry Group"
            error={errors.industry_group !== undefined}
            helperText={errors.industry_group?.message}
          >
            {industry.map((item) => (
              <MenuItem key={item.industryGroup} value={item.industryGroup}>
                {item.industryGroup}
              </MenuItem>
            ))}
          </SelectField>
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px', height: '200px' }} spacing={2}>
          <SwitchInput
            name="itVerticalLeadConversation"
            defaultValue={false}
            label="Have you had a conversation with the IT Vertical Lead about this request?"
          />
          {!itVerticalLeadConversation && (
            <Alert severity="info">
              Please make sure that you have a conversation with the IT Vertical Lead about this
              request prior to submission.{' '}
              {industry.find((item) => item.industryGroup === industryGroup) &&
                `Our records indicate that the industry lead
            for this group is 
            ${industry.find((item) => item.industryGroup === industryGroup)?.industryLead}.
            Please make sure they are aware of this request.`}
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px', height: '200px' }}
      >
        <Stack
          spacing={2}
          justifyContent="space-between"
          sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <Typography variant="body1" paragraph gutterBottom>
            Please provide the date this needs to be completed by. Normal requests typically are
            completed within 4 weeks depending on the nature of the request
          </Typography>
          <CompletionDate name="dateCompleted" placeholderText="Select date" />
        </Stack>
        <Stack
          spacing={2}
          justifyContent="space-between"
          sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <SwitchInput
            name="escalateRequest"
            defaultValue={false}
            label="Does this request need to be expidited?"
          />
          {escalateRequest && (
            <Alert severity="info">
              If this request needs to be expedited, we ask that you have your IT Vertical Lead (
              {industry.find((item) => item.industryGroup === industryGroup)?.industryLead}) reach
              out to us directly.
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack spacing={2} alignItems="flex-start" sx={{ minHeight: '100px' }}>
        <Typography variant="body1" paragraph gutterBottom>
          Software type<span style={{ color: 'red' }}>*</span>
        </Typography>
        <RadioButtonGroup
          name="softwareType"
          error={errors.softwareType !== undefined}
          helperText={errors.softwareType?.message}
          labelValue={[
            { value: 'commercial', label: 'Commercial' },
            { value: 'openSource', label: 'Open Source' },
          ]}
        />
      </Stack>
    </Box>
  );
};
