import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export const NavBar = ({ children }) => {
  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 8,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
                fontFamily: 'Rockwell',
              }}
            >
              RED|VENTURES
            </Typography>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
                fontFamily: 'Rockwell',
              }}
            >
              RED|VENTURES
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </>
  );
};

NavBar.propTypes = {
  children: PropTypes.node.isRequired,
};
