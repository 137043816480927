import React from 'react';
import { PropTypes } from 'prop-types';
import { Container, Box } from '@mui/material';

const PageContainer = ({ children }) => {
  return (
    <Container>
      <Box textAlign="left" margin={2}>
        {children}
      </Box>
    </Container>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
