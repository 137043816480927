import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';

import PageContainer from '../container/PageContainer';

export const LandingPage = () => {
  return (
    <PageContainer>
      <Box maxWidth="md" sx={{ margin: '1rem auto', padding: '1rem' }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h4" component="h1" gutterBottom>
          Welcome to the Technology Request Portal
        </Typography>
        <Typography sx={{ marginTop: '2rem' }} variant="body1" paragraph gutterBottom>
          At Red Ventures, we strive to ensure that the software solutions used within our
          organization meet the highest standards of security, compliance, and value. To streamline
          the acquisition of new technology and maintain a robust approval process, we have
          implemented a comprehensive procurement process. Please review the steps outlined below to
          understand the expectations and requirements for requesting new technology prior to doing
          so.
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1" paragraph gutterBottom>
          Step 1: Submit a Request Form To initiate the procurement process.
        </Typography>
        <Typography sx={{ my: '1rem' }} variant="body1" paragraph gutterBottom>
          This form will capture essential information about the proposed software solution,
          including its purpose, functionality, and estimated cost. The request form is designed to
          help us better understand your needs and ensure alignment with our company&#39;s goals.
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1" paragraph gutterBottom>
          Step 2: Security, Compliance, Legal, Procurement Review and VP Approval
        </Typography>
        <Typography sx={{ my: '1rem' }} variant="body1" paragraph gutterBottom>
          Once your request form is submitted, our dedicated teams from Security, Compliance, Legal,
          and Procurement will carefully review the software solution. This review process is
          crucial to ensure that the software meets our rigorous security and compliance standards,
          aligns with legal requirements, and delivers optimal value for our organization. The
          review will include an evaluation of the software&#39;s data privacy measures, security
          protocols, integration capabilities, contractual terms, and overall alignment with our IT
          infrastructure. Our teams will work diligently to assess the potential risks and benefits
          associated with the software solution. After the thorough review by the respective teams,
          the software will be submitted for final approval to one of our Vice Presidents. Their
          role is to evaluate the strategic fit of the software within our company&#39;s vision,
          assess the potential benefits, and ensure the expenditure aligns with the overall
          budgetary considerations. Their approval is crucial before we proceed with the procurement
          process.
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1" paragraph gutterBottom>
          Step 3: Procurement and Purchase
        </Typography>
        <Typography sx={{ my: '1rem' }} variant="body1" paragraph gutterBottom>
          Once the software receives approval from the Vice President, our Procurement team will
          take the necessary steps to procure the software licenses or subscriptions. This involves
          negotiating the contract terms, pricing, and any additional legal requirements to
          safeguard the interests of our organization. We will keep you informed throughout this
          process, providing updates on the status of your request and estimated timelines for
          procurement completion.
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1" paragraph gutterBottom>
          Step 4: Onboarding and Implementation
        </Typography>
        <Typography sx={{ my: '1rem' }} variant="body1" paragraph gutterBottom>
          Upon successful completion of the procurement process, our dedicated team will assist you
          with the onboarding and implementation of the new software. This includes coordinating
          training sessions, configuring the software to meet your specific needs, and ensuring a
          smooth transition to the new solution. If you have any questions or need assistance with
          the procurement process, please reach out to the procurement team, and we will be more
          than happy to assist you.
        </Typography>
        <Button
          sx={{ width: '100%', marginTop: '1rem', marginBottom: '2rem' }}
          variant="contained"
          disableElevation
        >
          <Link href="/request" underline="none" color="inherit">
            Begin Tech Intake Form
          </Link>
        </Button>
      </Box>
    </PageContainer>
  );
};
