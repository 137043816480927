import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const CompletionDate = ({ name, defaultValue, placeholderText }) => {
  const { control } = useFormContext();
  const date = new Date();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      sx={{ width: '100%' }}
      render={({ field }) => (
        <DatePicker
          {...field}
          placeholderText={placeholderText}
          sx={{ width: '100%', color: 'black' }}
          onChange={(date) => field.onChange(date)}
          selected={field.value}
          minDate={date.setDate(date.getDate() + 28)}
          className="form-control"
        />
      )}
    />
  );
};

CompletionDate.defaultProps = {
  defaultValue: '',
  placeholderText: '',
};

CompletionDate.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  placeholderText: PropTypes.string,
};
