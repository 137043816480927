import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';

export const SwitchInput = ({ name, label, defaultValue, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel disabled={disabled} control={<Switch />} label={label} />
        </FormGroup>
      )}
    />
  );
};

SwitchInput.defaultProps = {
  defaultValue: '',
  label: '',
  disabled: false,
};

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};
