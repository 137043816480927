import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const APIContext = React.createContext();
export const useAPI = () => useContext(APIContext);

const API_BASE_URL = process.env.REACT_APP_PORTFOLIO_API_URL;

export const APIProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const request = async ({
    method = 'GET',
    route,
    body = null,
    isAuth = true,
    headers = {},
    paramString = '',
  }) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    if (isAuth) {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${token}`;
    }

    const requestOptions = {
      method,
      url: API_BASE_URL + route + paramString,
      headers,
    };

    // since we pass in 'body' as a pre-serialized json string, axios needs
    // us to set the content-type otherwise it'll try to form-encode the json
    // string we pass in for the body
    if (body !== '') {
      requestOptions.data = body;
      requestOptions.headers['Content-Type'] = 'application/json';
    }

    let response;
    try {
      const apiResponse = await axios(requestOptions);

      // handle errors if we can
      if (apiResponse.status >= 300 && apiResponse.data && apiResponse.data.message) {
        response = {
          error:
            apiResponse.data?.message ||
            apiResponse.data ||
            `received status ${apiResponse.status}`,
        };
      }

      // success
      if (apiResponse.data) {
        response = apiResponse.data;
      }
    } catch (err) {
      // log and return error message
      console.error('requestError', { err, route });
      response = { error: err.message };
    }

    return response;
  };

  const getEmployees = async () => {
    const response = await request({
      method: 'GET',
      route: '/elc/employee',
    });

    return response;
  };

  const submitIntakeForm = async (intakeFormData) => {
    const response = await request({
      method: 'POST',
      route: '/workflow/intake',
      body: intakeFormData,
    });
    return response;
  };

  return (
    <APIContext.Provider
      value={{
        submitIntakeForm,
        getEmployees,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

APIProvider.defaultProps = {
  children: null,
};

APIProvider.propTypes = {
  children: PropTypes.node,
};
