import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';
import AuthProvider from './Providers/AuthProvider';
import { APIProvider } from './Providers/APIProvider';

import Router from './Router';

const theme = createTheme({
  palette: {
    primary: {
      main: red[900],
    },
    secondary: {
      main: grey[500],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body2: 'span',
        },
      },
    },
  },
});

const App = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      connection="okta"
      cacheLocation="localstorage"
    >
      <AuthProvider>
        <APIProvider>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </APIProvider>
      </AuthProvider>
    </Auth0Provider>
  );
};

export default App;
