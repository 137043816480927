import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from '@mui/material';

import { NavBar } from '../components/NavBar';

const AppContainer = ({ children }) => {
  return (
    <NavBar>
      <Container maxWidth="lg">{children}</Container>
    </NavBar>
  );
};

AppContainer.defaultProps = {
  children: null,
};

AppContainer.propTypes = {
  children: PropTypes.node,
};

export default AppContainer;
