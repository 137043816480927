import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Typography,
  Button,
  Container,
  Step,
  StepLabel,
  Stepper,
  Box,
  Stack,
  Alert,
} from '@mui/material';

import PageContainer from '../container/PageContainer';
import { RequestInformation } from '../pageComponents/RequestInformation';
import { VendorInformation } from '../pageComponents/VendorInformation';
import { SoftwareInformation } from '../pageComponents/SoftwareInformation';
import { ComplianceInformation } from '../pageComponents/ComplianceInformation';

import { useAPI } from '../Providers/APIProvider';

const getStringValues = (vals) => {
  const name = [];
  const emails = [];

  vals.forEach((item) => {
    name.push(item.name);
    emails.push(item.email);
  });
  return { name, emails };
};

export const Request = () => {
  const { submitIntakeForm } = useAPI();
  const [activeStep, setActiveStep] = useState(0);
  const [submissionError, setsubmissionError] = useState(false);

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const validationSchema = [
    // Request Information Validation
    Yup.object().shape({
      champions: Yup.array()
        .min(1, 'At least 1 Champion is required')
        .required('Champion is required'),
      vpTechCabLeaders: Yup.array()
        .min(1, 'At least 1 Senior Approval is required')
        .required('Senior Approval is required'),
      typeOfRequest: Yup.string().required('Request Type is required'),
      industry_group: Yup.string().required('Industry Group is required'),
      softwareType: Yup.string().required('Software Type is required'),
    }),

    // Vendor Information Validation
    Yup.object().shape({
      vendorName: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) => schema.required('Vendor Name is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      vendorContactName: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) => schema.required('Vendor Contact Name is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      vendorUrl: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) =>
          schema
            .required('Please provide a url')
            .matches(/^(https?:\/\/|\/).*/i, 'Please provide a valid URL')
            .max(255, 'Must be no longer than 255 characters'),
        otherwise: (schema) => schema.nullable(),
      }),
      vendorContactEmail: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) =>
          schema.required('Email is required').matches(emailRegex, 'Valid Email is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      requiredForBilling: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.nullable(),
      }),
      paymentSchedule: Yup.string().when('softwareType', {
        is: 'commercial',
        then: (schema) => schema.required('This field is required'),
      }),
    }),

    // Software Information Validation
    Yup.object().shape({
      nameOfTechnology: Yup.string().required('Software Name is required'),
      urlOfTheProduct: Yup.string()
        .required('Please provide a url')
        .matches(/^(https?:\/\/|\/).*/i, 'Please provide a valid URL')
        .max(255, 'Must be no longer than 255 characters'),
      whoUses: Yup.string().required('Who is using this technology is required'),
      description: Yup.string().required('Software Description is required'),
      typeOfRequest: Yup.string().required('Request Type is required'),
      proofOfConcept: Yup.string().required('Proof of Concept is required'),
      feedback_business_justification: Yup.string().required('Business Use Case is required'),
      desktopInstalled: Yup.string().required('This feild is required'),
      industry_group: Yup.string().required('Industry Group is required'),
      singleSignOn: Yup.string().required('This field is required'),
      responsibleName: Yup.string().required('Project Admin is required'),
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep]
    ? validationSchema[activeStep]
    : Yup.object();

  const methods = useForm({
    defaultValues: {
      champions: [],
      vpTechCabLeaders: [],
    },
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    trigger,
    formState: { isSubmitSuccessful, isSubmitting },
  } = methods;

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onSubmit = async (data) => {
    const submissionData = {
      typeOfRequest: data.typeOfRequest,
      technology: '',
      vendorName: data.vendorName,
      paymentSchedule: data.paymentSchedule,
      nameOfTechnology: data.nameOfTechnology,
      techCategory: data.techCategory,
      subCategory: data.subCategory,
      vpTechCabLeader: getStringValues(data.vpTechCabLeaders).emails.join(', '),
      vpTechCabEmail: getStringValues(data.vpTechCabLeaders).name.join(', '),
      champion: getStringValues(data.champions).name.join(', '),
      championEmail: getStringValues(data.champions).emails.join(', '),
      description: data.description,
      feedback_business_justification: data.feedback_business_justification,
      requesterName: null,
      requesterEmail: null,
      singleSignOn: data.singleSignOn,
      describeWhyNoSSO: '',
      requiredForBilling: data.requiredForBilling,
      softwareType: data.softwareType,
      proofOfConcept: data.proofOfConcept,
      proofOfConceptTimeline: '',
      urlOfTheProduct: data.urlOfTheProduct,
      whoUses: data.whoUses,
      teamName: '',
      estimatedAnnualPrice: '',
      vendorContactName: data.vendorContactName,
      vendorContactEmail: data.vendorContactEmail,
      certified: '',
      industry_group: data.industry_group,
      data_storage: '',
      responsibleName: data.responsibleName,
      responsibleEmail: '',
      anotherTeamInvolved: data.anotherTeamInvolved,
      whatTeams: '',
      desktopInstalled: data.desktopInstalled,
      escalateRequest: data.escalateRequest,
      dateToBegin: data.dateCompleted.toLocaleDateString(),
      escalationJustification: '',
      sysID: '',
      hasComplianceData: true,
      isDataProcessedExternally: data.isDataProcessedExternally,
      hasNetworkInterconnectivity: data.hasNetworkInterconnectivity,
      needsRvSystemAccess: data.needsRvSystemAccess,
      systemsSuppliedByVendor: data.systemsSuppliedByVendor,
      isRvDataStoredAtVendor: data.isRvDataStoredAtVendor,
      needsRvPhysicalAccess: data.needsRvPhysicalAccess,
      personalInformation: data.personalInformation,
      sharedRvDataTypes: Object.entries(data.sharedRvDataTypes)
        .filter((item) => item[1] === true)
        .map((item) => {
          return item[0];
        }),
      sharedCustomerOrPartnerDataTypes: Object.entries(data.sharedCustomerOrPartnerDataTypes)
        .filter((item) => item[1] === true)
        .map((item) => {
          return item[0];
        }),
    };

    const submitResp = await submitIntakeForm(submissionData);
    if (submitResp.error) {
      setsubmissionError(true);
    }
  };

  const steps = [
    {
      label: 'Request Information',
      optional: false,
      content: <RequestInformation />,
    },
    {
      label: 'Vendor Information',
      optional: false,
      content: <VendorInformation />,
    },
    {
      label: 'Software Information',
      optional: false,
      content: <SoftwareInformation />,
    },
    {
      label: 'Compliance Information',
      optional: false,
      content: <ComplianceInformation />,
    },
  ];

  return (
    <PageContainer>
      <Container disableGutters maxWidth="md" sx={{ marginTop: '2rem' }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h4" component="h1" gutterBottom>
          Tech Portfolio Intake Form
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stepper activeStep={activeStep} sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
              {steps.map((step) => {
                return (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Box sx={{ padding: '1rem', margin: '1rem' }}>
                <Stack
                  sx={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                    variant="h6"
                    component="h1"
                    gutterBottom
                  >
                    {' '}
                    Thanks for filling out the intake form. you may either review the data you have
                    entered or submit the form.
                  </Typography>
                  <Stack direction="row">
                    <Button
                      sx={{ width: '50% ', margin: '1rem' }}
                      variant="outlined"
                      onClick={() => setActiveStep(0)}
                      disabled={isSubmitting || isSubmitSuccessful}
                    >
                      Reset
                    </Button>
                    <Button
                      sx={{ width: '50% ', margin: '1rem' }}
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting || isSubmitSuccessful}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
                {isSubmitSuccessful && (
                  <Alert severity="success">
                    Your form was submitted successfully. Below are instructions on what will happen
                    next.
                  </Alert>
                )}
                {submissionError && (
                  <Alert severity="error">
                    There was an error submitting the form. Please reach out to the Procurement Team
                    for help. Thank you.
                  </Alert>
                )}
              </Box>
            ) : (
              <>
                {steps[activeStep].content}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 2,
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleNext} variant="outlined">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </>
            )}
          </form>
        </FormProvider>
      </Container>
    </PageContainer>
  );
};
