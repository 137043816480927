import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckBoxLabel } from './CheckBoxLabel';

export const CheckBoxGroup = ({ children }) => {
  return <FormGroup>{children}</FormGroup>;
};

CheckBoxGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CheckBoxItem = ({ labelItem, name, value, children, required }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormControlLabel
          sx={{ paddingBottom: '1rem', alignItems: 'flex-start' }}
          control={<Checkbox value={value} sx={{ paddingTop: '2px' }} />}
          label={<CheckBoxLabel label={labelItem}>{children}</CheckBoxLabel>}
          onChange={onChange}
          required={required}
        />
      )}
    />
  );
};

CheckBoxItem.defaultProps = {
  labelItem: '',
  required: false,
};

CheckBoxItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelItem: PropTypes.string,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};
