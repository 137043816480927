import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Stack, Typography, MenuItem, Alert } from '@mui/material';
import { BasicTextField } from '../components/BasicTextField';
import { SelectField } from '../components/SelectField';

export const VendorInformation = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const softwareType = watch('softwareType');
  const showVendorInputs = softwareType !== 'commercial';

  return (
    <Box
      sx={{
        background: '#fafafa',
        borderRadius: '10px',
        margin: '1rem',
        padding: '2rem',
        border: '1px solid #757575',
      }}
    >
      <Alert severity="info">
        The following only needs to be filled out if the software type is commercial
      </Alert>
      <Stack direction="row" spacing={4} justifyContent="space-between" sx={{ padding: '12px' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" gutterBottom>
            Name of Vendor<span style={{ color: 'red' }}>*</span>
          </Typography>
          <BasicTextField
            required
            name="vendorName"
            label="Vendor Name"
            disabled={showVendorInputs}
            error={errors.vendorName !== undefined}
            helperText={errors.vendorName?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" gutterBottom>
            Vendor Url<span style={{ color: 'red' }}>*</span>
          </Typography>
          <BasicTextField
            name="vendorUrl"
            label="Vendor Url"
            disabled={showVendorInputs}
            error={errors.vendorUrl !== undefined}
            helperText={errors.vendorUrl?.message}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ padding: '12px' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" gutterBottom>
            Vendor Contact Name<span style={{ color: 'red' }}>*</span>
          </Typography>
          <BasicTextField
            required
            name="vendorContactName"
            label="Vendor Contact Name"
            disabled={showVendorInputs}
            error={errors.vendorContactName !== undefined}
            helperText={errors.vendorContactName?.message}
          />
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }}>
          <Typography variant="body1" gutterBottom>
            Vendor Contact Email<span style={{ color: 'red' }}>*</span>
          </Typography>
          <BasicTextField
            name="vendorContactEmail"
            label="Vendor Contact Email"
            disabled={showVendorInputs}
            error={errors.vendorContactEmail !== undefined}
            helperText={errors.vendorContactEmail?.message}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ padding: '12px' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }} justifyContent="space-between">
          <Typography variant="body1" paragraph gutterBottom>
            What is the expected payment schedule for this software?
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="paymentSchedule"
            label="Payment Schedule"
            disabled={showVendorInputs}
            error={errors.paymentSchedule !== undefined}
            helperText={errors.paymentSchedule?.message}
          >
            <MenuItem value="annual">Annual</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="one-time">OneTime</MenuItem>
          </SelectField>
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }} justifyContent="space-between">
          <Typography variant="body1" paragraph gutterBottom>
            is a contract required?<span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="requiredForBilling"
            label="Contract Required"
            disabled={showVendorInputs}
            error={errors.requiredForBilling !== undefined}
            helperText={errors.requiredForBilling?.message}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
    </Box>
  );
};
