import React from 'react';

import { Box, Stack, Typography, Link } from '@mui/material';
import { CheckBoxGroup, CheckBoxItem } from '../components/CheckBoxGroup';
import { RadioButtonGroup } from '../components/RadioButtonGroup';

export const ComplianceInformation = () => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1" gutterBottom>
        Compliance Information (Required immediately)
      </Typography>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will Red Ventures’s data pass through, be stored on or processed on systems controlled by
          the vendor?
        </Typography>
        <RadioButtonGroup
          name="isDataProcessedExternally"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will Red Ventures’s network be connected to the vendor’s network?
        </Typography>
        <RadioButtonGroup
          name="hasNetworkInterconnectivity"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will the vendor’s personnel require access to Red Ventures’s computers, networks, or
          information resources?
        </Typography>
        <RadioButtonGroup
          name="needsRvSystemAccess"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will the vendor independently develop or supply computer software or hardware for use by
          Red Ventures or your customers?
        </Typography>
        <RadioButtonGroup
          name="systemsSuppliedByVendor"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will Red Ventures’s data or equipment containing Red Ventures’s data be stored at the
          vendor’s facility?
        </Typography>
        <RadioButtonGroup
          name="isRvDataStoredAtVendor"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Will the vendor physically access Red Ventures’s facilities that contain computer systems
          or information technology resources?
        </Typography>
        <RadioButtonGroup
          name="needsRvPhysicalAccess"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
      >
        <Typography variant="body1" paragraph gutterBottom sx={{ width: '60%' }}>
          Does this technology involve any collecting, receiving, or sharing of personal
          information, including hashed Personal Information?
        </Typography>
        <RadioButtonGroup
          name="personalInformation"
          labelValue={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Stack>
      <Typography variant="body1" paragraph gutterBottom sx={{ paddingTop: '2rem' }}>
        Please select the type of company data to which the vendor will have access. (Select all
        that apply.)
      </Typography>
      <Stack direction="row">
        <Stack spacing={2} sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Extremely Sensitive Data
          </Typography>
          <CheckBoxGroup>
            <CheckBoxItem
              name="sharedRvDataTypes.financialReporting"
              value="financialReporting"
              labelItem="Financial Reporting"
            >
              Examples include data from a Red Ventures’ accounting system or monetary asset
              management system
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.monetaryAssets"
              value="monetaryAssets"
              labelItem="Monetary Assets"
            >
              Examples include access to Red Ventures cash, bank accounts, cyber currency, or other
              such assets.
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.authentication"
              value="authentication"
              labelItem="Authentication credential or internal Encryption keys"
            >
              Such as username and password combinations, API keys, and TSI keys
            </CheckBoxItem>
          </CheckBoxGroup>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Medium Sensitive Data
          </Typography>
          <CheckBoxGroup>
            <CheckBoxItem
              name="sharedRvDataTypes.proprietaryConfidentialInformation"
              value="proprietaryConfidentialInformation"
              labelItem="Proprietary and confidential information"
            >
              Trade secrets, Business plans, Customer lists and contracts, and partners with whom we
              work
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.vulnerabilities"
              value="vulnerabilities"
              labelItem="Vulnerabilities"
            >
              such as Vulnerability scan reports or Penetration testing reports
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.sourceCode"
              value="sourceCode"
              labelItem="Source Code"
            >
              source code of a product you produce
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.employeesSensitivePII"
              value="employeesSensitivePII"
              labelItem="Employees Sensitive PII (Personal Identifiable Information)"
            >
              such as Employee names + social security numbers and Employee names + bank numbers,
              and employment records
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.insiderInformation"
              value="insiderInformation"
              labelItem="Insider Information"
            >
              such as Acquisition plans of a publicly traded company
            </CheckBoxItem>
          </CheckBoxGroup>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Low Sensitive Data
          </Typography>
          <CheckBoxGroup>
            <CheckBoxItem
              name="sharedRvDataTypes.unrestrictedInformation"
              value="unrestrictedInformation"
              labelItem="Unrestricted information"
            >
              such as Published research and Press releases
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedRvDataTypes.lessSensitiveInformation"
              value="lessSensitiveInformation"
              labelItem="Less Sensitive Information"
            >
              such as Employee work names and contact lists.
            </CheckBoxItem>
          </CheckBoxGroup>
        </Stack>
      </Stack>
      <Typography variant="body1" paragraph gutterBottom sx={{ paddingTop: '2rem' }}>
        Customer and partner data to which the vendor will have access. Refer to RV’s{' '}
        <Link href="https://security.rvdocs.io/policies/info-governance.html#_2-1-classification-categories">
          data classification categories
        </Link>{' '}
        and{' '}
        <Link href="https://docs.google.com/spreadsheets/d/1iscy5fTsQCqDR0xiaRQnjUy9U1H9Ia7eiw2ETQr9J5s/edit#gid=727902991">
          personal data library list
        </Link>{' '}
        for more information about how RV classifies data.
      </Typography>
      <Stack direction="row">
        <Stack spacing={2} sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Restricted
          </Typography>
          <CheckBoxGroup>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.restrictedPii"
              value="restrictedPii"
              labelItem="Restricted PII *(maps to Extreme Sensitivity > Sensitive PII)*"
            >
              - Personal information (PI) such as open-end financial account numbers (full checking
              or savings account numbers), credit report information, national ID numbers (SSN or
              equivalent), and driver’s license or state ID numbers, Customer names or addresses or
              telephone numbers in combination with their social security numbers or credit card
              numbers, or anything that could be used to authenticate to a customer account
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.PublicHealthInformation"
              value="PublicHealthInformation"
              labelItem="PHI (Public Health Information)"
            >
              Protected health information (PHI) as defined by HIPAA (such as Customer`&#39;`s
              medical records, Test results, Diagnoses, Treatment plans, or Insurance information.
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.PaymentCardInformation"
              value="PaymentCardInformation"
              labelItem="PCI (Payment Card Information)"
            >
              data as defined by PCI-DSS (such as Customer credit or debit card information)
            </CheckBoxItem>
          </CheckBoxGroup>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Typography variant="body1" paragraph gutterBottom>
            Sensitive
          </Typography>
          <CheckBoxGroup>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.pii"
              value="pii"
              labelItem="PII (Personal Identifiable Information)"
            >
              PII not considered restricted (such as Customer names, addresses or telephone numbers
              NOT in combination with social security numbers or credit card numbers), Medical
              information not covered by HIPAA
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.customerProprietaryInformation"
              value="customerProprietaryInformation"
              labelItem="Customer or Partner proprietary information"
            >
              such as Customer trade secrets, Business plans, Customer lists and contracts, or
              Financial data
            </CheckBoxItem>
            <CheckBoxItem
              name="sharedCustomerOrPartnerDataTypes.piiAdditionalAttributableInformation"
              value="piiAdditionalAttributableInformation"
              labelItem="PII and Additional attributable information"
            >
              such as Customer contact information and their spending history.
            </CheckBoxItem>
          </CheckBoxGroup>
        </Stack>
      </Stack>
    </Box>
  );
};
