import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export const CheckBoxLabel = ({ label, children }) => {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {label}
      </Typography>
      :
      <br />
      {children}
    </>
  );
};

CheckBoxLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
